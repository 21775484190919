// extracted by mini-css-extract-plugin
export var active = "header-module--active--byAzz";
export var authenticated = "header-module--authenticated--SoEju";
export var backArrowPresent = "header-module--back-arrow-present--YN4rV";
export var container = "header-module--container--O5DHA";
export var goBack = "header-module--go-back--XLIAU";
export var log = "header-module--log--4F1wA";
export var login = "header-module--login--fJ2J7";
export var logo = "header-module--logo--WAoX3";
export var menu = "header-module--menu--hMDNj";
export var menuButton = "header-module--menu-button---G2Zu";
export var menuButtonSpecial = "header-module--menu-button-special--72vQm";
export var sos = "header-module--sos--WtWfO";
export var unauthenticated = "header-module--unauthenticated--lSzPa";