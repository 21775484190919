import React from 'react';
import MenuIconClosed from '@/assets/icons/menu-closed.inline.svg';
import MenuIconOpen from '@/assets/icons/menu-open.inline.svg';
import * as styles from './menu-button.module.scss';

export default function MenuButton({ onClick, open }) {
  const MenuIcon = open ? MenuIconOpen : MenuIconClosed;

  return (
    <button className="btn--clean">
      <MenuIcon className={styles.icon} onClick={onClick} />
    </button>
  );
}