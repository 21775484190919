import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Logo from '@/assets/icons/logo.inline.svg';
import { useTranslation } from '@/src/contexts/translation.context';
import BackArrow from '@/assets/icons/back-arrow.inline.svg';
import MobileMenu from '../mobile-menu/mobile-menu';
import MenuButton from '../menu-button/menu-button';
import * as styles from './header.module.scss';
import { useIdleTimer } from 'react-idle-timer';
import { useApi } from '@/src/contexts/api.context';

export type HeaderProps = {
  landing?: boolean;
  unauthenticated?: boolean;
  pageContext?: object;
  specialHeaderContent?: string;
  goBack?: any;
};

export default function Header({ landing, unauthenticated, pageContext, specialHeaderContent, goBack }: HeaderProps) {
  const translation = useTranslation();
  const [_, setHref] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const apiService = useApi();

  const onIdle = async () => {
    try {
      await apiService.logout();
    } finally {
      sos();
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 5
  });

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  const toggleMenu = (event) => {
    setMenuOpen(!menuOpen);
  };
  const SpecialContentInHeader = () => (
    <h2>{specialHeaderContent}</h2>
  );

  const sos = () => {
    if(!unauthenticated) {
      apiService.logout();
    }

    window.location.href = 'https://google.com';
  };

  return (
    <>
      <div className={
        styles.container +
        (unauthenticated ? ` ${styles.unauthenticated}` : '') +
        (!unauthenticated ? ` ${styles.authenticated}` : '')
      }>
        <div className={styles.menuButton + ' ' + (goBack ? styles.menuButtonSpecial : '')} >
          {goBack ? <div className={styles.goBack} onClick={goBack}><BackArrow /></div> : <MenuButton onClick={toggleMenu} open={menuOpen}></MenuButton>}

          <button className={styles.sos + ' btn--clean' + ' ' + (goBack ? styles.sosButtonSpecial : '')} onClick={sos}>
            <span className="sos__inner">{translation.translate('header.sos')}</span>
          </button>
        </div>

        <Link className={styles.logo + ' ' + (goBack ? styles.backArrowPresent : '')} to={unauthenticated ? `${translation.linkPrefix}/` : `${translation.linkPrefix}/app`}>
          {specialHeaderContent ? <SpecialContentInHeader /> : <Logo></Logo>}
        </Link>

        <button className={styles.log + ' btn--clean'}>
          {!unauthenticated ? null : <Link className={styles.login} to={`${translation.linkPrefix}/login`}><span>{translation.translate('authentication.log_in')}</span></Link>}
        </button>
      </div>
      {<nav className={styles.menu + ' ' + (menuOpen ? styles['active'] : '')}><MobileMenu pageContext={pageContext} toggleMenu={toggleMenu} sos={sos} landing={landing} unauthenticated={unauthenticated} /></nav>}
    </>
  );
}