import { TranslationService } from "@/src/services/translation.service";
import { navigate } from "gatsby";
import React, { useState } from "react";
import Calendar from "@/assets/icons/calendar.inline.svg";
import Profile from "@/assets/icons/profile.inline.svg";
import Warning from "@/assets/icons/warning.inline.svg";
import { Link } from "gatsby";
import * as styles from "./mobile-menu.module.scss";
import { useApi } from "@/src/contexts/api.context";
import { useLocation } from "@reach/router";

// Compondent for menu on mobile. Another needed for desktop, where entire menu is visable at the top.

// component for divider

const ColoredLine = () => <hr className={styles.hr} />;
// State change when same component is clicked

export default function MobileMenu({
  pageContext,
  toggleMenu,
  landing,
  unauthenticated,
  sos,
}) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageContext));
  const [errorMessage, setErrorMessage] = useState(null);

  const signOut = async () => {
    try {
      await apiService.logout();
      await navigate(`${translation.linkPrefix}/login`, { replace: true });
    } catch (err) {
      setErrorMessage(translation.translate("authentication.exit_error"));
    }
  };

  return (
    <div
      className={
        styles.menuWrapper +
        " " +
        (!unauthenticated
          ? ` ${styles.authenticated}`
          : ` ${styles.unauthenticated}`)
      }
    >
      {!unauthenticated && (
        <>
          <h1 className={styles.goToHeading}>
            {translation.translate("menu.go_to_heading")}
          </h1>
          <div className={styles.menu__top}>
            <Link
              to={`${translation.appLinkPrefix}`}
              className={styles.goTo}
              onClick={toggleMenu}
            >
              <Calendar className={styles.goToIconWrapper} />
              <div className={styles.goToText}>
                {translation.translate("menu.calendar")}
              </div>
            </Link>

            <Link
              to={`${translation.appLinkPrefix}/profile`}
              className={styles.goTo}
              onClick={toggleMenu}
            >
              <Profile className={styles.goToIconWrapper} />
              <div className={styles.goToText}>
                {translation.translate("menu.my_profile")}
              </div>
            </Link>

            <Link
              to={`${translation.appLinkPrefix}/send-report`}
              className={styles.goTo + " " + styles.sendReport}
              onClick={toggleMenu}
            >
              <Warning className={styles.goToIconWrapper} />
              <div className={styles.goToText}>
                {translation.translate("menu.send_report")}
              </div>
            </Link>
          </div>

          <span className={styles.menu__separator}>
            <ColoredLine />
          </span>
        </>
      )}

      <div
        className={
          styles.subMenu__wrapper +
          " " +
          (unauthenticated ? "" : styles.authenticated)
        }
      >
        <div className={styles.aboutTheService}>
          <Link
            className={styles.serviceLink}
            to={
              landing
                ? `${translation.linkPrefix}#usage`
                : `${translation.linkPrefix}/#usage`
            }
            onClick={toggleMenu}
          >
            {translation.translate("menu.about_the_service")}
          </Link>
          <Link
            className={styles.serviceLink}
            to={`${translation.linkPrefix}/faq`}
          >
            {translation.translate("menu.questions_and_answers")}
          </Link>
          <Link
            className={styles.serviceLink}
            to={`${translation.linkPrefix}/contact`}
            onClick={toggleMenu}
          >
            {translation.translate("menu.contact")}
          </Link>
          <Link
            className={styles.serviceLink}
            to={`${translation.linkPrefix}/companies-and-associations`}
            onClick={toggleMenu}
          >
            {translation.translate("menu.companies_and_associations")}
          </Link>
          <div className={styles.langWrapper}>
            <ul className={styles.langList}>
              <li
                className={`${
                  translation.lang === "sv" ? styles.isCurrent : ""
                }`}
              >
                <Link
                  className={styles.serviceLink}
                  to={`${useLocation().pathname.replace(/^\/\w+\//, "/")}`}
                  onClick={toggleMenu}
                >
                  SV
                </Link>
              </li>
              <li
                className={`${
                  translation.lang === "en" ? styles.isCurrent : ""
                }`}
              >
                <Link
                  className={styles.serviceLink}
                  to={`/en${useLocation().pathname.replace(
                    `/${translation.lang}`,
                    ""
                  )}`}
                  onClick={toggleMenu}
                >
                  EN
                </Link>
              </li>
              <li
                className={`${
                  translation.lang === "fi" ? styles.isCurrent : ""
                }`}
              >
                <Link
                  className={styles.serviceLink}
                  to={`/fi${useLocation().pathname.replace(
                    `/${translation.lang}`,
                    ""
                  )}`}
                  onClick={toggleMenu}
                >
                  FI
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {!unauthenticated ? (
          <button className={styles.sosDesktop + " btn--clean"} onClick={sos}>
            <span className="sos__inner">
              {translation.translate("header.sos")}
            </span>
          </button>
        ) : (
          ""
        )}
      </div>

      <div className={styles.buttonsWrapper}>
        <button className={styles.sos + " btn--clean"} onClick={sos}>
          <span className="sos__inner">
            {translation.translate("header.sos")}
          </span>
        </button>

        <button className={styles.log + " btn--clean"}>
          {!unauthenticated ? null : (
            <Link
              className={styles.login}
              to={`${translation.linkPrefix}/login`}
            >
              <span>{translation.translate("authentication.log_in")}</span>
            </Link>
          )}
        </button>
      </div>

      {!unauthenticated && (
        <>
          <span className={styles.menu__separator}>
            <ColoredLine />
          </span>

          <button
            className={styles.sign__out + " btn--clean"}
            onClick={signOut}
          >
            <span>{translation.translate("profile.sign_out")}</span>
          </button>
          {errorMessage && (
            <div
              className="form__error-message"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></div>
          )}
        </>
      )}
    </div>
  );
}
